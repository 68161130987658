<template>
  <v-container fluid>
    <ai_agent />
    <v-app-bar dark color="#28596f" app>
      <v-app-bar-nav-icon>
        <v-img contain height="50" :src="this.weblogo"></v-img>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="truncate">
        {{ this.manifest.name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tabs v-if="!isXs">
        <v-tabs-slider color="warning"></v-tabs-slider>
        <v-spacer></v-spacer>
        <v-tab v-for="item in menu" :key="item.name">
          {{ item.name }}
        </v-tab>
      </v-tabs>

      <div v-if="!isXs">
        <Loginform />
      </div>

      <div v-if="isXs">
        <v-btn fab small @click="dialog = !dialog">
          <v-icon> mdi-dots-vertical</v-icon>
        </v-btn>
      </div>
    </v-app-bar>
    <v-carousel cycle height="100vh">
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
        <v-sheet color="transparent" height="100%" tile>
          <v-layout fill-height align-center justify-center class="banner">
            <v-flex xs10 sm10 md10 align="center" justify="bottom">
              <v-row class="fill-height" align="center" justify="end">
                <v-col md="12">
                  <div class="text-h3 white--text">
                    <h1 class="text-md-h2 text-h4 font-weight-bold textShadow">
                      {{ item.title }}
                    </h1>
                    <p class="text-md-h4 text-h6 textShadow">{{ item.text }}</p>
                  </div>
                </v-col>
                <!-- <v-col md="12" class="text-center">
                  <v-btn dark x-large bottom center class="success" to="Registration">Registrese GRATIS AQUI !</v-btn>
                </v-col> -->
              </v-row>
            </v-flex>
          </v-layout>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <v-divider></v-divider>
    <v-layout fill-height align-center justify-center>
      <v-flex xs12 sm8 md10 align-center justify-center> </v-flex>
    </v-layout>

    <v-layout fill-height align-center justify-center>
      <v-flex xs12 sm8 md10 align-center justify-center>
        <v-row fill-height>
          <v-col cols="12" sm="6" md="6" lg="4">
            <v-card height="100%" elevation="0">
              <v-card-title class="card_title text-wrap">
                Compra y Venta<br />de Vehículos Usados
              </v-card-title>
              <v-img
                cover
                height="250"
                class="imgsize"
                src="../../assets/image0.webp"
              ></v-img>
              <v-card-text class="text-justify">
                Gestión Integral de Inventarios y Transacciones: Optimiza la compra, venta
                y gestión de vehículos usados con herramientas avanzadas de seguimiento y
                documentación.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="4" align="left" justify="center">
            <v-card height="100%" elevation="0">
              <v-card-title class="card_title text-wrap">
                Plataforma Exclusiva<br />para Concesionarios y Agentes
              </v-card-title>
              <v-img
                cover
                height="250"
                class="imgsize"
                src="../../assets/image3.webp"
              ></v-img>
              <v-card-text class="text-justify">
                Solución B2B para el Sector Automotriz: Diseñada específicamente para
                concesionarios y agentes de ventas, proporciona una plataforma robusta
                para la gestión de inventarios, clientes y procesos de negocio.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="4" align="left" justify="center">
            <v-card height="100%" elevation="0">
              <v-card-title class="card_title text-wrap">
                Red de Proveedores<br />y Servicios Automotrices
              </v-card-title>
              <v-img
                cover
                height="250"
                class="imgsize"
                src="../../assets/image5.webp"
              ></v-img>
              <v-card-text class="text-justify">
                Integración con Proveedores y Servicios: Incluye centros de servicio
                automotriz, talleres de mecánica, aseguradoras y auto lavados para una
                gestión completa y eficiente.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-spacer class="pa-12"></v-spacer>

    <v-footer dark color="#28596f">
      <v-card flat tile width="100%" color="transparent" class="white--text text-center">
        <v-card-text class="white--text">
          <v-row align="center" justify="center">
            <v-col cols="auto">
              New Global Market Corporation.<br />
              info@newglobalmarket.ca
            </v-col>
            <v-col cols="auto">
              NGM Barranquilla<br />
              barranquilla@newglobalmarket.ca
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-btn class="text-capitalize font-weight-light white--text" to="About" text>
            Terminos y condiciones
          </v-btn>
          <v-btn class="text-capitalize font-weight-light white--text" text>
            Políticas
          </v-btn>
          <v-btn class="text-capitalize font-weight-light white--text" text>
            Características del producto
          </v-btn>
          <v-btn class="text-capitalize font-weight-light white--text" text>
            Seguridad
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text text-capitalize font-weight-light">
          {{ new Date().getFullYear() }} — New Global Market Corporation.
        </v-card-text>
      </v-card>
    </v-footer>
    <v-bottom-navigation
      dark
      horizontal
      color="secondary"
      v-if="$vuetify.breakpoint.xsOnly"
    >
      <v-btn color="green" block to="/Login">
        <v-icon>mdi-login</v-icon> Iniciar sesión
      </v-btn>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import Loginform from "../../components/Login.vue";
import ai_agent from "../../components/ai/ai_agent.vue";

export default {
  name: "Welcome",
  components: { Loginform, ai_agent },
  data() {
    return {
      weblogo: null,
      manifest: {},
      fab: null,
      color: "",
      flat: null,
      isXs: false,
      menu: [
        {
          id: 0,
          name: "Inicio",
          icon: "home",
          to: "/",
          color: "warning",
        },
        {
          id: 2,
          name: "Clientes",
          icon: "employees",
          color: "warning",
          to: "Inventario",
        },
        {
          id: 30,
          name: "Proveedores",
          icon: "supplier",
          color: "warning",
          to: "Inventario",
        },
        {
          id: 40,
          name: "Reparto",
          icon: "truck",
          color: "warning",
          to: "Inventario",
        },
      ],
      items: [
        {
          src: require("../../assets/image0.webp"),
          title: "Compra y Venta de Vehículos Usados",
          text: "",
        },
        {
          src: require("../../assets/image1.webp"),
          title: "Acceso desde dispositivos móviles",
          text: "",
        },
        {
          src: require("../../assets/image2.webp"),
          title: "ERP para Concesionarios",
          text: "",
        },
        {
          src: require("../../assets/image3.webp"),
          title: "Plataforma Exclusiva para Concesionarios y Agentes",

          text: "",
        },
        {
          src: require("../../assets/image4.webp"),
          title: "Gestión de Inventarios y Transacciones",
          text: "",
        },
        {
          src: require("../../assets/image5.webp"),
          title: "Red de Proveedores y Servicios Automotrices",
          text: "",
        },
        {
          src: require("../../assets/image6.webp"),
          title: "Gestión de reportes técnicos en línea",
          text: "",
        },
        {
          src: require("../../assets/image7.webp"),
          title: "Inventario digital detallado",
          text: "Registro del inventario en cada transacción.",
        },
      ],
      dialog: false,
      icons: {
        project: "mdi-text-box-search-outline",
        weather: "mdi-weather-partly-snowy-rainy",
        crops: "mdi-sprout-outline",
        poly: "mdi-vector-polygon",
        dB: "mdi-database-cog-outline",
        chart: "mdi-chart-bell-curve-cumulative",
        clt: "mdi-account-circle-outline",
        set: "mdi-cog-transfer-outline",
        info: "mdi-book-information-variant",
        cale: "mdi-calendar-month-outline",
        invoice: "mdi-receipt",
        pedido: "mdi-file-document-edit-outline",
        truck: "mdi-truck-fast-outline",
        warehouse: "mdi-warehouse",
        inventory: "mdi-package-variant-closed",
        supplier: "mdi-truck-check-outline",
        orders: "mdi-file-document-edit-outline",
        store: "mdi-store-outline",
        clock: "mdi-clock-check-outline",
        map: "mdi-map-marker-radius",
        home: "mdi-home-circle-outline",
        forklift: "mdi-forklift",
        cash: "mdi-cash-register",
        employees: "mdi-account-multiple-outline",
        account: "mdi-account-lock-outline",
        accountGroup: "mdi-account-group-outline",
        account_tie: "mdi-account-tie",
        fish: "mdi-fish",
        cube_not: "mdi-package-variant-closed-remove",
        cube_pos: "mdi-package-variant-plus",
        garage: "mdi-garage-variant",
        currency: "mdi-currency-usd",
      },
      open: [],
    };
  },
  beforeMount() {
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");
    this.weblogo = "../../" + root + "/logo.webp";
  },
  mounted() {
    this.$store.dispatch("setNavStatus", false);
    this.$store.dispatch("set_menu", false);
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    this.$vuetify.goTo(0);
  },
  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
    "$store.getters.getManifest"(newVal) {
      if (newVal) {
        this.manifest = newVal;
      }
    },
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 850;
    },

    toTop() {
      this.$vuetify.goTo(0);
    },
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    updateForm(item) {
      //if (item.length > 0) console.log(item[0].name, item[0].to);
      if (item[0].to) this.$router.push(item[0].to);
    },
    updateOpen(openItems) {
      this.open = openItems.length ? [openItems[openItems.length - 1]] : [];
    },
    logout() {
      this.$store.state.navStatus = false;
      this.$router.push("/login/");
    },
  },
};
</script>

<style>
.card_title {
  font-size: 1.5rem;
  font-weight: bold;
  height: 100px !important;
  text-wrap: pretty !important;
}
.textShadow {
  text-shadow: rgb(0, 0, 0) 5px 0 15px;
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
</style>
