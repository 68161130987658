<template>
  <v-bottom-sheet v-model="open" fullscreen>
    <v-sheet class="text-center" height="100%" color="transparent">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm12 md4 lg4>
            <v-row>
              <v-col cols="12">
                <v-progress-circular
                  :size="140"
                  :width="3"
                  indeterminate
                  rotate="180"
                  :color="this.webSetting.loading_color"
                >
                  <v-progress-circular
                    :size="130"
                    :width="2"
                    :color="this.webSetting.loading_color"
                    indeterminate
                  >
                    <v-avatar color="white" size="130">
                      <v-img contain height="120" :src="logo"></v-img>
                    </v-avatar>
                  </v-progress-circular>
                </v-progress-circular>
              </v-col>
              <v-col cols="12">
                <b>{{ this.webSetting.name }}</b>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </v-container>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { webserver } from "../services/webserver.js";
export default {
  name: "WelcomeSplash",
  async created() {
    this.webSetting = await this.fetchAsync();
    this.$store.dispatch("set_manifest", this.webSetting);
  },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    color: {
      type: String,
      default: "#000000",
    },
  },
  data() {
    return {
      logo: null,
      webSetting: {},
    };
  },
  mounted() {
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");

    const favicon = document.getElementById("favicon");
    favicon.href = root + "/favicon.ico";
    this.logo = root + "/logo.webp";
    const root_manifest = website.replaceAll(".", "") + ".json";
    var mnfst = document.querySelectorAll('[rel="manifest"]');
    mnfst[0].href = root_manifest;
    this.get_company();
  },
  computed: {
    imageStyle() {
      let width = this.size + "px";
      return {
        width,
      };
    },
  },
  methods: {
    fetchAsync() {
      const website = this.$store.getters.getHost;
      const root = website.replaceAll(".", "");
      let modulePath = root + ".json";
      return fetch(modulePath)
        .then((response) => response.text())
        .then((response) => JSON.parse(response));
    },
    setColor() {
      document.documentElement.style.setProperty("--splash-color", this.color);
    },
    closeSplash() {
      this.$emit("close", true);
    },
    async get_company() {
      var qry = {
        table: "stores",
        filters: [{ field: "url", operator: "=", value: this.$store.getters.getHost }],
      };
      let promise = new Promise((resolve, reject) => {
        webserver(
          "get_table",
          qry,
          function (data) {
            resolve(data);
          },
          function () {
            reject([]);
          }
        );
      });
      let result = await promise;
      // console.log(result);
      if (result.length > 0) {
        this.$store.dispatch("setCompany", result[0]);

        const encryptedText = this.CryptoJS.AES.encrypt(
          JSON.stringify(result[0]),
          process.env.VUE_APP_QR4
        ).toString();
        window.localStorage.setItem("company", encryptedText);
      }
      setTimeout(this.closeSplash, 3000);
    },
  },
};
</script>
