var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-navigation-drawer',{staticClass:"navigation-drawer",attrs:{"value":_vm.$store.getters.getMenuStatus && _vm.$store.state.profile.user_access,"width":_vm.bestWidth > 600 ? 300 : _vm.bestWidth,"app":""},on:{"input":function (val) { return (_vm.drawer = val); }},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list-item',[_c('v-avatar',{attrs:{"rounded":""}},[_c('v-icon',{attrs:{"color":"green","large":""}},[_vm._v("mdi-information-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$store.state.company.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$store.state.company.group_name))]),_vm._v(" "+_vm._s(_vm.$store.state.company.address)+" ")],1),_c('v-list-item-action',{on:{"click":function($event){return _vm.call_contact(_vm.item.mobile)}}},[_c('v-icon',[_vm._v("mdi-phone-outline")])],1)],1)]},proxy:true}])},[_c('v-list-item',[_c('v-avatar',{attrs:{"rounded":""}},[_c('v-img',{attrs:{"icon":"","contain":"","src":_vm.weblogo,"height":"35"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$store.getters.getManifest.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$store.state.profile.user_access))])],1),_c('v-list-item-action',[_c('v-icon',{on:{"click":_vm.setdrawer}},[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-treeview',{attrs:{"return-object":"","item-key":"id","activatable":"","open":_vm.open,"open-on-click":"","items":_vm.links},on:{"update:active":_vm.updateForm,"update:open":_vm.updateOpen},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(!item.icon)?_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-folder-open" : "mdi-folder")+" ")]):_c('v-icon',{attrs:{"color":item.color}},[_vm._v(" "+_vm._s(item.icon)+" ")])]}}])}),_c('v-divider')],1),_c('v-app-bar',{attrs:{"dark":"","value":_vm.$store.getters.getNavStatus,"app":""}},[_c('v-app-bar-nav-icon',{on:{"click":_vm.setdrawer}}),_c('v-toolbar-title',[_vm._v(_vm._s(this.$store.getters.company.name))]),_c('v-spacer'),_c('v-menu',{attrs:{"transition":"slide-x-transition","bottom":"","right":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-avatar',[(_vm.$store.getters.profile.Picture)?_c('img',{attrs:{"src":_vm.$store.getters.profile.Picture}}):_c('v-icon',[_vm._v("mdi-account-circle-outline")])],1)],1)]}}])},[[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-account-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(this.$store.state.profile.name)+" ")]),_c('small',[_vm._v(_vm._s(this.$store.state.profile.email))])],1)],1),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-logout-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Salir ")])],1)],1)],1)]],2)],1),_c('v-main',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1),_c('AddToHomeScreen'),_c('v-snackbar',{staticStyle:{"z-index":"9999"},attrs:{"timeout":_vm.snackToast.timeout_error,"absolute":"","centered":"","vertical":"","outlined":"","max-width":"400","color":_vm.snackToast.color,"elevation":"24","rounded":"","content-class":"text-center"},scopedSlots:_vm._u([(_vm.snackToast.timeout_error == -1)?{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackToast.dialog = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}:null],null,true),model:{value:(_vm.snackToast.dialog),callback:function ($$v) {_vm.$set(_vm.snackToast, "dialog", $$v)},expression:"snackToast.dialog"}},[_vm._v(" "+_vm._s(this.snackToast.text)+" ")]),_c('v-scale-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"},{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],staticClass:"fabbtn",attrs:{"fab":"","fixed":"","bottom":"","right":"","color":"secondary"},on:{"click":_vm.toTop}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1)],1),_c('v-dialog',{attrs:{"value":_vm.$store.getters.getLoading,"hide-overlay":"","persistent":"","max-width":"480"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Trabajando ... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c('WelcomeSplash',{attrs:{"open":_vm.splashDialog},on:{"close":function($event){_vm.splashDialog = !_vm.splashDialog}}}),_c('v-snackbar',{attrs:{"bottom":"","right":"","value":_vm.updateExists,"timeout":-1,"color":"primary"}},[_vm._v(" Una actualización disponible. "),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.refreshApp}},[_vm._v(" ACTUALIZAR ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }