<template>
  <v-app id="inspire">
    <v-navigation-drawer
      class="navigation-drawer"
      :value="$store.getters.getMenuStatus && $store.state.profile.user_access"
      @input="(val) => (drawer = val)"
      :width="bestWidth > 600 ? 300 : bestWidth"
      app
    >
      <v-list-item>
        <v-avatar rounded>
          <v-img icon contain :src="weblogo" height="35"></v-img>
        </v-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ $store.getters.getManifest.name }}</v-list-item-title>
          <v-list-item-subtitle>{{
            $store.state.profile.user_access
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon @click="setdrawer">mdi-close</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-divider></v-divider>
      <!-- <v-list-item to="docs">
        <v-avatar rounded>
          <v-icon color="green">mdi-file-document-multiple-outline</v-icon>
        </v-avatar>
        <v-list-item-content>
          <v-list-item-title>Documentos</v-list-item-title>
          <v-list-item-subtitle>Lista de pedidos</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item> -->

      <v-treeview
        return-object
        item-key="id"
        activatable
        :open="open"
        open-on-click
        @update:active="updateForm"
        @update:open="updateOpen"
        :items="links"
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="!item.icon">
            {{ open ? "mdi-folder-open" : "mdi-folder" }}
          </v-icon>
          <v-icon :color="item.color" v-else>
            {{ item.icon }}
          </v-icon>
        </template>
      </v-treeview>

      <v-divider></v-divider>

      <template v-slot:append>
        <v-list-item>
          <v-avatar rounded>
            <v-icon color="green" large>mdi-information-outline</v-icon>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $store.state.company.name }}</v-list-item-title>
            <v-list-item-subtitle>{{
              $store.state.company.group_name
            }}</v-list-item-subtitle>
            {{ $store.state.company.address }}
          </v-list-item-content>
          <v-list-item-action @click="call_contact(item.mobile)">
            <v-icon>mdi-phone-outline</v-icon>
          </v-list-item-action>
        </v-list-item>
        <!-- <button @click="install">install</button> -->
      </template>
    </v-navigation-drawer>

    <v-app-bar dark :value="$store.getters.getNavStatus" app>
      <v-app-bar-nav-icon @click="setdrawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ this.$store.getters.company.name }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu transition="slide-x-transition" bottom right offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-avatar>
              <img
                :src="$store.getters.profile.Picture"
                v-if="$store.getters.profile.Picture"
              />
              <v-icon v-else>mdi-account-circle-outline</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <template>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-account-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ this.$store.state.profile.name }}
                </v-list-item-title>
                <small>{{ this.$store.state.profile.email }}</small>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="logout">
              <v-list-item-avatar>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> Salir </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </v-app-bar>

    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>

    <AddToHomeScreen />

    <v-snackbar
      v-model="snackToast.dialog"
      :timeout="snackToast.timeout_error"
      absolute
      centered
      vertical
      outlined
      max-width="400"
      :color="snackToast.color"
      elevation="24"
      style="z-index: 9999"
      rounded
      content-class="text-center"
    >
      {{ this.snackToast.text }}
      <template v-slot:action="{ attrs }" v-if="snackToast.timeout_error == -1">
        <v-btn color="red" text v-bind="attrs" @click="snackToast.dialog = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-scale-transition>
      <v-btn
        fab
        v-show="fab"
        v-scroll="onScroll"
        fixed
        bottom
        right
        color="secondary"
        @click="toTop"
        class="fabbtn"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>

    <v-dialog :value="$store.getters.getLoading" hide-overlay persistent max-width="480">
      <v-card color="primary" dark>
        <v-card-text>
          Trabajando ...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <WelcomeSplash :open="splashDialog" @close="splashDialog = !splashDialog" />

    <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
      Una actualización disponible.
      <v-btn text @click="refreshApp"> ACTUALIZAR </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import update from "./mixins/update";
import { webserver } from "./services/webserver.js";
import createItem from "./utils/createUser.js";
import AddToHomeScreen from "./components/PwaInstall.vue";
import WelcomeSplash from "./components/splash.vue";
import createProfile from "./utils/createProfile.js";

const TIME_IN_BOUNDARY = 8;
const TIME_OUT_BOUNDARY = 20;
const INTERVAL = 5 * 60 * 1000;

export default {
  components: { AddToHomeScreen, WelcomeSplash },
  beforeMount() {
    this.$store.dispatch("setHost", window.location.host);
    this.$store.dispatch("setHost", "adm.vialmar.co");

    var getProfile = window.localStorage.getItem("sc_c");
    if (getProfile) {
      var profile = JSON.parse(
        this.CryptoJS.AES.decrypt(getProfile, process.env.VUE_APP_QR4).toString(
          this.CryptoJS.enc.Utf8
        )
      );
      console.log(profile);
      this.profile = profile;
      window.token = profile.token;
      var getStore = window.localStorage.getItem("sc_s");
      var store = JSON.parse(
        this.CryptoJS.AES.decrypt(getStore, process.env.VUE_APP_QR4).toString(
          this.CryptoJS.enc.Utf8
        )
      );
      window.company = store;
      this.$store.dispatch("setProfile", profile);
      this.$store.dispatch("set_menuItems", this.menu[profile.user_access]);
      this.$store.dispatch("setCompany", store);
      this.$store.dispatch("setNavStatus", true);
      // this.$store.dispatch("set_menu", true);
      console.log(window.company);
      this.$router.push("/DashBoard");
    } else {
      //this.$store.dispatch("setNavStatus", false);
      //this.$store.dispatch("set_menu", false);
      //this.$router.push("/");
    }
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");
    this.weblogo = "./" + root + "/logo.webp";
    //console.log(this.weblogo);
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
  },
  mounted() {
    this.get_company();
    window.addEventListener("resize", this.onResize, { passive: true });
    this.$vuetify.goTo(0);
    this.onResize();
  },
  data: () => ({
    bestWidth: window.innerWidth,
    deferredPrompt: null,
    weblogo: null,
    manifest: {},
    splashDialog: true,
    updateExists: false,
    company: null,
    dialog: true,
    profile: { name: null },
    drawer: true,
    isXs: false,
    item: createItem(),
    navStatus: true,
    open: [],
    icons: {
      project: "mdi-text-box-search-outline",
      weather: "mdi-weather-partly-snowy-rainy",
      crops: "mdi-sprout-outline",
      poly: "mdi-vector-polygon",
      dB: "mdi-database-cog-outline",
      chart: "mdi-chart-bell-curve-cumulative",
      clt: "mdi-account-circle-outline",
      set: "mdi-cog-transfer-outline",
      info: "mdi-book-information-variant",
      cale: "mdi-calendar-month-outline",
      invoice: "mdi-receipt",
      pedido: "mdi-file-document-edit-outline",
      truck: "mdi-truck-fast-outline",
      warehouse: "mdi-warehouse",
      inventory: "mdi-package-variant-closed",
      supplier: "mdi-truck-check-outline",
      orders: "mdi-file-document-edit-outline",
      store: "mdi-store-outline",
      clock: "mdi-clock-check-outline",
      map: "mdi-map-marker-radius",
      home: "mdi-home-circle-outline",
      forklift: "mdi-forklift",
      cash: "mdi-cash-register",
      employees: "mdi-account-multiple-outline",
      account: "mdi-account-lock-outline",
      accountGroup: "mdi-account-group-outline",
      account_tie: "mdi-account-tie",
      fish: "mdi-fish",
    },
    links: [],
    menu: {
      Sysop: [
        {
          id: 99,
          name: "Configuración",
          description: "Proveedores, Listas de precio proveedores",
          icon: "mdi-cog-transfer-outline",
          color: "secondary",
          children: [
            {
              id: 0,
              name: "Compañías",
              description: "Creear, editar o eliminar Empresas",
              icon: "mdi-account-group-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "Companies", table: "stores", to: "Clients" },
            },
            {
              id: 91,
              name: "Mi Perfil",
              description: "Editar información de contacto",
              icon: "mdi-store-outline",
              color: "secondary",
              to: "MyStore",
            },
            {
              id: 2,
              name: "Vendedores",
              description: "Creear, editar o eliminar vendedores",
              icon: "mdi-account-group-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "Vendedores", table: "sellers", to: "Clients" },
            },
            {
              id: 94,
              name: "Clientes",
              description: "Creear, editar o eliminar Restaurantes",
              icon: "mdi-account-group-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "Clientes", table: "clients", to: "Clients" },
            },
            {
              id: 92,
              name: "Proveedores",
              description:
                "Creear, editar o eliminar contactos de proveedores mayoristas",
              icon: "mdi-truck-cargo-container",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "Proveedores", table: "suppliers", to: "Clients" },
            },
            {
              id: 93,
              name: "Lista de precios",
              description: "Creear, editar o eliminar lista de precios ",
              icon: "mdi-file-document-edit-outline",
              to: "Catalogue",
              color: "secondary",
            },
          ],
        },
        {
          id: 98,
          name: "FacturaE",
          icon: "set",
          color: "#CDDC39",
          children: [
            {
              id: 981,
              name: "Empresas",
              description: "Creear, editar o eliminar lista de precios ",
              icon: "mdi-file-document-edit-outline",
              to: "Companies",
              color: "secondary",
            },
            {
              id: 982,
              name: "Settings FE",
              description: "Creear, editar o eliminar lista de precios ",
              icon: "mdi-file-document-edit-outline",
              to: "SettingsFE",
              color: "secondary",
            },
          ],
        },
        {
          id: 10,
          name: "PUC",
          description: "Plan Unico de Cuentas",
          icon: "mdi-calculator-variant-outline",
          color: "secondary",
          to: "Puc",
        },
      ],
      Admin: [
        {
          id: 7,
          name: "Dashboard",
          description: "Resumen operación",
          icon: "mdi-chart-multiple",
          color: "secondary",
          to: "DashBoard",
        },
        {
          id: 10,
          name: "Compras",
          description: "Proveedores, Listas de precio proveedores",
          color: "secondary",
          icon: "mdi-truck-fast-outline",
          to: "Doc_r",
          params: { docType: "ORP", to: "docs" },
          children: [],
        },

        {
          id: 99,
          name: "Configuración",
          description: "Proveedores, Listas de precio proveedores",
          icon: "mdi-cog-transfer-outline",
          color: "secondary",
          children: [
            {
              id: 91,
              name: "Mi Perfil",
              description: "Editar información de contacto",
              icon: "mdi-store-outline",
              color: "secondary",
              to: "MyStore",
            },
            {
              id: 2,
              name: "Vendedores",
              description: "Creear, editar o eliminar vendedores",
              icon: "mdi-account-group-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "Vendedores", table: "sellers", to: "Clients" },
            },
            {
              id: 94,
              name: "Clientes",
              description: "Creear, editar o eliminar Restaurantes",
              icon: "mdi-account-group-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "Clientes", table: "clients", to: "Clients" },
            },
            {
              id: 92,
              name: "Proveedores",
              description:
                "Creear, editar o eliminar contactos de proveedores mayoristas",
              icon: "mdi-truck-cargo-container",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "Proveedores", table: "suppliers", to: "Clients" },
            },
            {
              id: 93,
              name: "Lista de precios",
              description: "Creear, editar o eliminar lista de precios ",
              icon: "mdi-file-document-edit-outline",
              to: "Catalogue",
              color: "secondary",
            },
          ],
        },
      ],
      Ventas: [
        {
          id: 7,
          name: "Dashboard",
          description: "Resumen operación",
          icon: "mdi-chart-multiple",
          color: "secondary",
          to: "DashBoard",
        },
        {
          id: 10,
          name: "Compras",
          description: "Proveedores, Listas de precio proveedores",
          color: "secondary",
          icon: "mdi-truck-fast-outline",
          to: "Doc_r",
          params: { docType: "ORP", to: "docs" },
          children: [],
        },
        {
          id: 20,
          name: "Caja",
          description: "Proveedores, Listas de precio proveedores",
          color: "secondary",
          icon: "mdi-cash-register",
          // to: "Doc_r",
          // params: { docType: "ORP", to: "docs" },
          children: [
            {
              id: 21,
              name: "Comprobante de Ingreso",
              description: "Lista de Ingresos",
              icon: "mdi-truck-fast-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "CIN", to: "docs" },
            },
            {
              id: 22,
              name: "Comprobante de Egreso",
              description: "Lista de Egresos",
              icon: "mdi-truck-fast-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "CEG", to: "docs" },
            },
          ],
        },

        {
          id: 30,
          name: "Ventas",
          description: "Peidos, Despachos, Factura Electrónica, POS",
          icon: "mdi-receipt-text-check-outline",
          color: "secondary",
          children: [
            {
              id: 94,
              name: "Clientes",
              description: "Creear, editar o eliminar Restaurantes",
              icon: "mdi-account-group-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "Clientes", table: "clients", to: "Clients" },
            },
            {
              id: 31,
              name: "Facturas electrónicas",
              description: "Lista de Facturas Electrónicas",
              icon: "mdi-truck-fast-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "FE", to: "docs" },
            },
            {
              id: 32,
              name: "POS",
              description: "Lista de Documentos POS",
              icon: "mdi-truck-fast-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "POS", to: "docs" },
            },
            {
              id: 33,
              name: "Pedidos",
              description: "Gestionar y crear pedidos de clientes",
              icon: "mdi-file-document-multiple-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "OR", to: "docs" },
            },
          ],
        },

        {
          id: 40,
          name: "Inventario",
          description: "Ingresos y Egresos de Inventario",
          icon: "mdi-forklift",
          color: "secondary",
          children: [
            {
              id: 41,
              name: "Ingreso a Bodega",
              description: "Ingresos a Inventario",
              icon: "mdi-forklift",
              color: "green",
              to: "Doc_r",
              params: { docType: "I_IN", to: "docs" },
            },
            {
              id: 42,
              name: "Salida de Bodega",
              description: "Salidas de Inventario",
              icon: "mdi-forklift",
              color: "red",
              to: "Doc_r",
              params: { docType: "I_OUT", to: "docs" },
            },
          ],
        },

        //this.$router.push({ name: "Doc_e", params: { docType: this.doc_type } });
        // {
        //   id: 0,
        //   name: "Caja",
        //   description: "Ingresos y Egresos de caja",

        //   color: "secondary",
        //   to: "ClientOrders",
        //   children: docs_lst().docType,
        // },

        // {
        //   id: 4,
        //   name: "Lista de precios",
        //   description: "Creear, editar o eliminar lista de precios ",
        //   icon: "mdi-file-document-edit-outline",
        //   to: "Catalogue",
        //   color: "secondary",
        // },

        // {
        //   id: 99,
        //   name: "Configuración",
        //   description: "Editar información de contacto",
        //   icon: "mdi-cog-transfer-outline",
        //   color: "secondary",
        //   to: "Settings",
        // },
      ],
      Sales: [
        {
          id: 7,
          name: "Dashboard",
          description: "Resumen operación",
          icon: "mdi-chart-multiple",
          color: "secondary",
          to: "DashBoard",
        },
        {
          id: 10,
          name: "Compras",
          description: "Proveedores, Listas de precio proveedores",
          color: "secondary",
          icon: "mdi-truck-fast-outline",
          to: "Doc_r",
          params: { docType: "ORP", to: "docs" },
          children: [],
        },
        {
          id: 20,
          name: "Caja",
          description: "Proveedores, Listas de precio proveedores",
          color: "secondary",
          icon: "mdi-cash-register",
          // to: "Doc_r",
          // params: { docType: "ORP", to: "docs" },
          children: [
            {
              id: 21,
              name: "Comprobante de Ingreso",
              description: "Lista de Ingresos",
              icon: "mdi-truck-fast-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "CIN", to: "docs" },
            },
            {
              id: 22,
              name: "Comprobante de Egreso",
              description: "Lista de Egresos",
              icon: "mdi-truck-fast-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "CEG", to: "docs" },
            },
          ],
        },

        {
          id: 30,
          name: "Ventas",
          description: "Peidos, Despachos, Factura Electrónica, POS",
          icon: "mdi-receipt-text-check-outline",
          color: "secondary",
          children: [
            {
              id: 94,
              name: "Clientes",
              description: "Creear, editar o eliminar Restaurantes",
              icon: "mdi-account-group-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "Clientes", table: "clients", to: "Clients" },
            },
            {
              id: 31,
              name: "Facturas electrónicas",
              description: "Lista de Facturas Electrónicas",
              icon: "mdi-truck-fast-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "FE", to: "docs" },
            },
            {
              id: 32,
              name: "POS",
              description: "Lista de Documentos POS",
              icon: "mdi-truck-fast-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "POS", to: "docs" },
            },
            {
              id: 33,
              name: "Pedidos",
              description: "Gestionar y crear pedidos de clientes",
              icon: "mdi-file-document-multiple-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "OR", to: "docs" },
            },
          ],
        },

        {
          id: 40,
          name: "Inventario",
          description: "Ingresos y Egresos de Inventario",
          icon: "mdi-forklift",
          color: "secondary",
          children: [
            {
              id: 41,
              name: "Ingreso a Bodega",
              description: "Ingresos a Inventario",
              icon: "mdi-forklift",
              color: "green",
              to: "Doc_r",
              params: { docType: "I_IN", to: "docs" },
            },
            {
              id: 42,
              name: "Salida de Bodega",
              description: "Salidas de Inventario",
              icon: "mdi-forklift",
              color: "red",
              to: "Doc_r",
              params: { docType: "I_OUT", to: "docs" },
            },
          ],
        },

        //this.$router.push({ name: "Doc_e", params: { docType: this.doc_type } });
        // {
        //   id: 0,
        //   name: "Caja",
        //   description: "Ingresos y Egresos de caja",

        //   color: "secondary",
        //   to: "ClientOrders",
        //   children: docs_lst().docType,
        // },

        // {
        //   id: 4,
        //   name: "Lista de precios",
        //   description: "Creear, editar o eliminar lista de precios ",
        //   icon: "mdi-file-document-edit-outline",
        //   to: "Catalogue",
        //   color: "secondary",
        // },

        // {
        //   id: 99,
        //   name: "Configuración",
        //   description: "Editar información de contacto",
        //   icon: "mdi-cog-transfer-outline",
        //   color: "secondary",
        //   to: "Settings",
        // },
      ],
      Owner: [
        {
          id: 0,
          name: "DashBoard",
          description: "Indicadores de la operación",
          icon: "mdi-finance",
          to: "DashBoard",
        },
        {
          id: 1,
          name: "Proveedores",
          description: "Creear, editar o eliminar contactos de proveedores",
          icon: "mdi-store-outline",
          color: "secondary",
          to: "Proveedores",
        },
        {
          id: 2,
          name: "Catalogo Categorias",
          description: "Creear, editar o eliminar lista de precios ",
          icon: "mdi-file-document-edit-outline",
          to: "Catalogue",
          color: "secondary",
        },
        {
          id: 46,
          name: "Catalogo Proveedores",
          description: "Creear, editar o eliminar lista de precios de mayoristas",
          icon: "mdi-file-document-edit-outline",
          to: "Material",
          color: "secondary",
        },
        {
          id: 55,
          name: "Análisis de precios",
          description: "Creear, editar o eliminar lista de precios de mayoristas",
          icon: "mdi-file-document-edit-outline",
          to: "Material_prices",
          color: "secondary",
        },
        {
          id: 58,
          name: "Análisis de Mejores precios",
          description: "Creear, editar o eliminar lista de precios de mayoristas",
          icon: "mdi-file-document-edit-outline",
          to: "Material_supplier",
          color: "secondary",
        },
        {
          id: 42,
          name: "Clientes",
          description: "Creear, editar o eliminar Restaurantes",
          icon: "mdi-silverware-fork-knife",
          to: "Clientes",
          color: "secondary",
        },
        {
          id: 43,
          name: "Editar menús",
          description: "Creear, editar o eliminar Productos del menú",
          icon: "mdi-silverware-fork-knife",
          to: "QR4",
          color: "secondary",
        },

        {
          id: 45,
          name: "Nuestra gente",
          description:
            "Creear, editar o eliminar colaboradores, alidos, proveedores logísticos",
          icon: "mdi-account-multiple-outline",
          to: "Empleados",
          color: "secondary",
        },
        {
          id: 44,
          name: "Admin Usuarios",
          description: "Creear, editar o eliminar cuentas de acceso a la plataforma.",
          icon: "mdi-account-lock-outline",
          to: "Usuarios",
          color: "secondary",
        },

        {
          id: 87,
          name: "Mapa",
          description: "Mapa de la georeferenciación de restaurantes y proveedores.",
          icon: "mdi-map-marker-radius",
          color: "secondary",
          to: "ClientsMap",
        },
        {
          id: 88,
          name: "Tarjeta Digital",
          description: "QR con los datos de contacto personal.",
          icon: "mdi-card-account-details-outline",
          to: "BusinessCard",
          color: "secondary",
        },
        {
          id: 90,
          name: "Donde estamos?",
          description: "Donde esta nuestra gente.",
          icon: "mdi-map-marker-radius",
          color: "secondary",
          to: "mypeople",
        },
        {
          id: 91,
          name: "TPV",
          description: "Terminal punto de venta",
          icon: "mdi-map-marker-radius",
          color: "secondary",
          to: "tpv",
        },
        {
          id: 93,
          name: "Mailer",
          description: "Bulk Mailer",
          icon: "mdi-email-fast-outline",
          to: "Marketing",
        },
        {
          id: 94,
          name: "Puntos de Venta",
          description: "Creear, editar o eliminar Restaurantes",
          icon: "mdi-silverware-fork-knife",
          to: "Pos",
          color: "secondary",
        },
        {
          id: 9,
          name: "Contenidos",
          description: "Resumen operación",
          icon: "mdi-chart-multiple",
          color: "secondary",
          to: "contents",
        },
      ],
      CarDealer: [
        {
          id: 1000,
          name: "Car Inventory",
          description: "Car Inventory",
          icon: "mdi-chart-multiple",
          color: "secondary",
          to: "CarItem",
        },
      ],
      Cliente: [
        {
          id: 7,
          name: "Dashboard",
          description: "Resumen operación",
          icon: "mdi-chart-multiple",
          color: "secondary",
          to: "DashBoard",
        },
        {
          id: 10,
          name: "Compras",
          description: "Proveedores, Listas de precio proveedores",
          color: "secondary",
          icon: "mdi-truck-fast-outline",
          children: [
            {
              id: 102,
              name: "Ordenes de Compra",
              description: "Lista de Ordenes de Compra",
              icon: "mdi-truck-fast-outline",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "OR", to: "docs" },
            },
            {
              id: 92,
              name: "Proveedores",
              description:
                "Creear, editar o eliminar contactos de proveedores mayoristas",
              icon: "mdi-truck-cargo-container",
              color: "secondary",
              to: "Supplier",
            },
          ],
        },
      ],
    },
    tracker: null,
    fab: null,
    mini: true,
    valid: false,
    reg_dialog: false,
    f_email_val: [
      (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Correo no valido",
    ],
    f_required: [(v) => !!v || "Requerido"],
    snackToast: {
      timeout_error: 2000,
      dialog: false,
      text: "",
      color: "success",
    },
    loading_status: false,
  }),
  mixins: [update],
  computed: {},
  watch: {
    "$store.getters.getManifest"(newVal) {
      if (newVal) {
        this.manifest = newVal;
      }
    },
    "$store.getters.getToast"(newVal) {
      if (newVal) {
        this.snackToast = newVal;
      }
    },
    "$store.getters.getMenuStatus"(newVal) {
      if (newVal) {
        this.drawer = newVal;
      }
    },
    "$store.getters.profile"(newVal) {
      if (newVal) {
        this.links = this.menu[newVal.user_access];
        this.user = window.user;
        this.$store.dispatch("set_menuItems", this.menu[newVal.user_access]);
        //this.track();
      }
    },
    "$store.getters.getMenuItems"(newVal) {
      if (newVal) {
        this.links = newVal;
        //this.track();
      }
    },
  },
  beforeDestroy() {
    //clearInterval(this.tracker);
  },
  methods: {
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    get_company() {
      var qry = {
        table: "stores",
        filters: [{ field: "url", operator: "=", value: this.$store.getters.getHost }],
      };
      //console.log("get_company", qry);
      webserver("get_table", qry, (data) => {
        //console.log("get_company ->", data);
        if (data.length > 0) {
          data[0].logo =
            "https://" +
            this.$store.getters.getHost +
            "/logos/" +
            data[0].account +
            "/" +
            data[0].code +
            "/logo.webp";
          this.$store.dispatch("setCompany", data[0]);
        }
      });
    },
    // profile_submit(e) {
    //   e.preventDefault();
    //   this.loading_status = true;
    //   this.client.agent = "NGM";
    //   this.client.status = "Inactive";
    //   this.client.account = "mayorista.app";
    //   var qry = {
    //     account: "mayorista.app",
    //     table: "clients",
    //     data: [this.client],
    //   };
    //   webserver("put_table", qry, (data) => {
    //     this.loading_status = false;
    //     const encryptedText = this.CryptoJS.AES.encrypt(
    //       JSON.stringify(data),
    //       process.env.VUE_APP_QR4
    //     ).toString();
    //     window.localStorage.setItem("cli", encryptedText);
    //     this.$store.dispatch("setProfile", data);
    //     this.snackToast = {
    //       dialog: true,
    //       text: "Sus datos han sido registrados con EXITO !",
    //       color: "success",
    //     };
    //     this.reg_dialog = false;
    //   });
    // },
    setdrawer() {
      this.$store.dispatch("set_menu", !this.$store.getters.getMenuStatus);
    },
    onResize() {
      this.isXs = window.innerWidth < 850;
      this.bestWidth = window.innerWidth;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    ChangePasswordbtn() {
      this.item = {
        account: window.profile.account,
        code: window.profile.code,
        store_id: window.company.code,
        user_email: window.profile.email,
        user_name: window.profile.name,
        user_password: "",
      };
      this.dialog = true;
    },
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    updateForm(item) {
      //console.log(item);
      if (item[0].to)
        this.$router.push({
          name: item[0].to,
          params: item[0].params,
          query: item[0].query,
        });
    },
    updateOpen(openItems) {
      //.log("updateOpen", openItems);
      this.open = openItems.length ? [openItems[openItems.length - 1]] : [];
    },
    logout() {
      localStorage.removeItem("sc_c");
      this.$store.dispatch("setProfile", createProfile());

      this.$router.push("/");
    },
    track() {
      this.tracker = setInterval(async () => {
        var d = new Date().getHours(); // for now
        //console.log(d);
        if (d >= TIME_IN_BOUNDARY && d <= TIME_OUT_BOUNDARY) {
          try {
            const res = await this.getLocation();
            var dta = {
              account: this.$store.state.profile.account,
              user_code: this.$store.getters.profile.code,
              user_name: this.$store.getters.profile.name,
              location: JSON.stringify(res),
            };
            var qry = {
              account: this.$store.state.profile.account,
              table: "tracking",
              data: [dta],
            };
            //console.log(qry);
            webserver("put_table", qry, () => {});
          } catch (e) {
            console.error(e);
          }
        }
      }, INTERVAL);
    },
    getLocation() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (res) => {
              resolve([res.coords.latitude, res.coords.longitude]);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
        } else {
          reject("No Geolocation support.");
        }
      });
    },
  },
};
</script>

<style lang="css">
.fabbtn {
  bottom: 80px !important;
}
.floating-btn {
  top: 80px !important;
  z-index: 1000;
}
</style>
