<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    max-width="400px"
    :scrollable="$vuetify.breakpoint.xsOnly"
    light
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="success" dark v-bind="attrs" v-on="on">
        <span>Ingresar</span>
      </v-btn>
    </template>
    <v-form ref="form" v-model="valid" @submit="login">
      <v-card round elevation="10" dark color="#28596f" justify="center">
        <v-card-title>
          Iniciar Sesión <v-spacer></v-spacer>
          <v-icon @click="dialog = !dialog"> mdi-close </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text align="center" class="pa-2">
          <v-avatar size="130">
            <v-img contain height="120" :src="weblogo"></v-img>
          </v-avatar>
        </v-card-text>
        <v-card-text>
          <v-text-field
            light
            background-color="white"
            v-if="storelst.length === 0"
            v-model="credential.user_email"
            :rules="f_required"
            solo
            required
            prepend-inner-icon="mdi-account-outline"
            hide-details
            dense
          >
          </v-text-field>
          <v-text-field
            light
            prepend-inner-icon="mdi-lock-outline"
            background-color="white"
            class="mt-3 mb-3"
            v-if="storelst.length === 0"
            v-model="credential.user_password"
            type="password"
            :rules="f_required"
            solo
            required
            hide-details
            dense
            autocomplete
          />

          <v-select
            solo
            v-if="storelst.length > 0"
            v-model="credential.store"
            :items="storelst"
            label="Centro Productivo"
            item-text="name"
            item-value="store_id"
            height="50px"
            hide-details
            dense
          >
          </v-select>

          <v-card-actions>
            <v-btn block ref="mybtn" color="success" type="submit" @click="validate">
              Continuar
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { webserver } from "../services/webserver.js";
import createProfile from "../utils/createProfile.js";
import createStore from "../utils/createStore.js";
import createUser from "../utils/createParty.js";

export default {
  beforeMount() {
    this.company = this.$store.getters.company;
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");
    this.weblogo = "../../" + root + "/logo.webp";
  },
  mounted() {
    this.$store.dispatch("setNavStatus", false);
    this.$store.dispatch("set_menu", false);
  },
  data() {
    return {
      dialog: false,
      finger_dialog: false,
      weblogo: null,
      lhost: window.location.hostname,
      accept_terms: false,
      errors: [],
      valid: false,
      f_required: [(v) => !!v || "Requerido"],
      loading_status: false,
      credential: {
        store: null,
        user_email: null,
        user_password: "",
        token: null,
      },
      storelst: [],
      profile: createProfile(),
      company: createStore(),
      user: createUser(),
      manifest: {},
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    login(e) {
      e.preventDefault();
      var md5 = require("md5");
      var qry = {
        user_email: this.credential.user_email,
        user_password: md5(this.credential.user_password),
        user_token: this.credential.token,
        account: this.company.account,
        store: this.credential.store,
      };
      var metodo = "pre_login_b";
      if (this.credential.store) {
        metodo = "login";
      }
      console.log(qry);
      webserver(metodo, qry, (data) => {
        console.log(data);
        if (metodo === "login") {
          this.profile.name = data.profile.user_name;
          this.profile.email = data.profile.user_email;
          this.profile.token = data.profile.token;
          this.profile.picture = data.profile.picture;
          this.profile.socialName = "WEB";
          this.profile.account = data.company.account;
          this.profile.code = data.profile.code;
          this.profile.user_access = data.profile.user_access;
          this.profile.user_id = data.profile.user_id;

          this.$store.dispatch("setProfile", this.profile);
          this.$store.dispatch("setCompany", data.company);

          window.token = data.token;
          window.profile = this.profile;
          window.company = data.company;
          window.user = data.user;
          const encryptedText = this.CryptoJS.AES.encrypt(
            JSON.stringify(this.profile),
            process.env.VUE_APP_QR4
          ).toString();
          window.localStorage.setItem("sc_c", encryptedText);

          const encryptedStore = this.CryptoJS.AES.encrypt(
            JSON.stringify(data.company),
            process.env.VUE_APP_QR4
          ).toString();
          window.localStorage.setItem("sc_s", encryptedStore);

          this.$store.dispatch("setNavStatus", true);
          this.$store.dispatch("set_menu", true);

          if (this.nextUrl) {
            this.$router.push(this.nextUrl);
          } else {
            this.$router.push("/DashBoard");
          }
        } else {
          if (data.length > 1) {
            this.storelst = data;
          } else {
            this.credential.store = data[0].store_id;
            this.$refs.mybtn.$el.click();
          }
        }
      });
    },
    popupwindow(url, title, w, h) {
      var left = screen.width / 2 - w / 2;
      var top = screen.height / 2 - h / 2;
      return window.open(
        url,
        title,
        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
          w +
          ", height=" +
          h +
          ", top=" +
          top +
          ", left=" +
          left
      );
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
    open_instagram(data) {
      window.open("https://www.instagram.com/" + data, "_blank");
    },
    open_whatsapp(data) {
      data = data.replace("-", "");
      data = data.replace("(", "");
      data = data.replace(")", "");
      data = data.replace(" ", "");
      var URL =
        "https://api.whatsapp.com/send?phone=+57" +
        data +
        "&text=" +
        encodeURI("Deseo más información ...");
      return URL;
    },
  },
  watch: {
    "$store.getters.getManifest"(newVal) {
      if (newVal) {
        this.manifest = newVal;
      }
    },
    "$store.getters.company"(newVal) {
      if (newVal) {
        this.company = newVal;
      }
    },
  },
};
//background: url("bg-welcome.jpg") no-repeat center center fixed;
</script>
