<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="bestWidth > 600 ? 450 : bestWidth"
    :height="this.best_height"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fixed
        fab
        dark
        color="blue"
        v-bind="attrs"
        v-on="on"
        absolute
        top
        right
        class="floating-btn"
      >
        <v-icon>mdi-chat</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar dark color="#28596f">
          <v-toolbar-title>{{ $store.getters.getManifest.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text ref="contentContainer" class="overflow-y-auto">
        <v-list>
          <v-list-item
            v-for="(message, index) in chatHistory"
            :key="index"
            :class="message.role === 'user' ? 'bubble-mine' : 'bubble-theirs'"
          >
            <v-list-item-avatar>
              <v-avatar>
                <v-icon v-if="message.role === 'user'">mdi-account-circle-outline</v-icon>
                <v-icon v-else> mdi-face-agent</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content v-html="formattedText(message.content)">
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions class="pa-0">
        <v-bottom-navigation grow :app="$vuetify.breakpoint.xsOnly">
          <v-text-field
            ref="messageInput"
            :loading="loading_chat"
            loader-height="2px"
            v-model="userInput"
            append-icon="mdi-send"
            hide-details
            rounded
            clearable
            :label="
              loading_chat
                ? $store.getters.getManifest.name + ' esta escribiendo ...'
                : 'Escribe un mensaje'
            "
            type="text"
            @click:append="add_message"
            @keyup="checkMsg"
          ></v-text-field>
        </v-bottom-navigation>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { NGMGpt } from "../../services/webserver.js";
export default {
  data() {
    return {
      best_height: window.innerHeight,
      bestWidth: window.innerWidth,
      dialog: false,
      userInput: "",
      chatHistory: [],
      loading_chat: false,
      thread_id: null,
    };
  },
  mounted() {
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.best_height = window.innerHeight;
      if (window.innerWidth < 480) {
        this.best_width = window.innerWidth;
      } else {
        this.best_width = window.innerWidth / 2;
      }
    },
    checkMsg(e) {
      if (e.keyCode === 13) {
        this.add_message();
      } else {
        return;
      }
    },
    async add_message() {
      if (!this.userInput.trim()) return;

      var message = {
        role: "user",
        content: [{ type: "text", text: this.userInput }],
      };
      this.chatHistory.push({ role: "user", content: this.userInput });
      this.userInput = "";
      this.$nextTick(() => {
        const container = this.$refs.contentContainer;
        container.scrollTop = container.scrollHeight;
        this.closeKeyboard();
      });

      var qry = {
        thread_id: this.thread_id,
        message: message,
      };
      this.loading_chat = true;
      let promise = new Promise((resolve, reject) => {
        NGMGpt(
          "add_message",
          qry,
          function (data) {
            resolve(data);
          },
          function () {
            reject([]);
          }
        );
      });
      let response = await promise;
      this.thread_id = response.thread_id;
      this.loading_chat = false;
      var first_id = response.messages.first_id;
      var dat = response.messages.data;
      var msg = dat.find((o) => o.id === first_id);

      const botMessageContent = msg.content[0].text.value;
      const botMessage = { role: "bot", content: botMessageContent };
      this.chatHistory.push(botMessage);

      this.$nextTick(() => {
        const container = this.$refs.contentContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    formattedText(texto) {
      // Detectar y aplicar formato HTML a los títulos
      texto = texto.replace(/^(#+)\s*(.*)$/gm, function (_, hashTags, titleText) {
        const nivel = hashTags.length;
        return `<h${nivel}>${titleText}</h${nivel}>`;
      });

      // Detectar y aplicar formato HTML a los elementos destacados
      texto = texto.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

      // Detectar y aplicar formato HTML a las listas
      texto = texto.replace(/^-\s+(.*)$/gm, "<li>$1</li>");
      texto = texto.replace(/<\/li>\n<li>/g, "</li><li>");
      texto = texto.replace(/(<li>.*<\/li>)/gs, "<ul>$1</ul>");

      // Detectar y aplicar formato HTML a las tablas
      texto = texto.replace(
        /^\|\s*([^|]+)\s*\|\s*([^|]+)\s*\|\s*([^|]+)\s*\|$/gm,
        function (_, col1, col2, col3) {
          return `<tr><td>${col1.trim()}</td><td>${col2.trim()}</td><td>${col3.trim()}</td></tr>`;
        }
      );
      texto = texto.replace(/(<tr>.*<\/tr>)/gs, "<table>$1</table>");

      // Detectar y aplicar formato HTML a los párrafos
      texto = texto.replace(/^(?!<[hultd]).+$/gm, "<p>$&</p>");

      return texto;
    },
    generatePrompt() {
      return this.chatHistory
        .map((message) => `${message.role}: ${message.content}`)
        .join("\n");
    },
    scrollToEnd() {
      const container = this.$refs.messagesContainer;
      container.scrollTop = container.scrollHeight;
    },
    closeKeyboard() {
      const input = this.$refs.messageInput;
      if (input) {
        input.blur();
      }
    },
  },
};
</script>

<style scoped>
.bubble-mine {
  justify-content: flex-end !important;
  background-color: rgb(227, 238, 238) !important;
}
.bubble-theirs {
  justify-content: flex-start !important;
}
</style>
