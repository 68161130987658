export default (data = {}) => {
    return {
        userid: null,
        email: null,
        name: null,
        picture: null,
        socialName: null,
        user_access: false,
        ...data
    }
}